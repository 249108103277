import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder, Underline } from "../styles/ui.styles";

const CardImageStyle: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

const AboutUs: React.FC = () => {
  return (
    <>
      <SEO title={"About Us"} description={"About Us"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <First>
            <h1>
              SCIENCE <br /> BEHIND SAILING
            </h1>
            <p>
              The team that develop Fastrrr is made up of sailors with great
              deal of experience. We know racing from the smallest dinghy
              (Optimist) to bigger boats, whether she is monohull or multihull.
              We have a combined racing experience of over two decades.
            </p>
          </First>
          <StaticImage
            style={{ width: "100%" }}
            src={"../images/about1.jpg"}
            alt={"Sailing competition"}
          />
          <Second>
            <p>
              Fastrrr is a unique solution that allows you to analyze your boat,
              sails and your own performance in the racing or training
              conditions. Certainly, many people have experienced that it is
              difficult to find the target-boat-speed of a custom designed or
              even one-design sailboat. Nowadays, it is already possible to view
              polar charts that are published by sailmakers, but in many cases,
              this can only be used as points of reference. In order to work
              with accurate and up-to-the-second data
            </p>
            <p>
              it is essential to continuously measure and process data. This is
              what Fastrrr does by automatically logging and analyzing the
              available data. You can easily compare your performance at the end
              of the day. You no longer need to back-up your workouts in your
              training diary and look for past performance since all measured
              data is synchronized in the user&apos;s own virtual storage space.
              You can retrieve the information anytime.
            </p>
          </Second>
          <Crew>
            <Title>
              <h2>Crew</h2>
              <Underline />
            </Title>
            <Grid>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/maxim_kozeli.jpg"}
                  alt={"Maxim Szigeti"}
                />
                <Shape />
                <h4>Maxim Szigeti</h4>
                <p>CEO, Co-founder</p>
              </Card>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/stacey_kozeli.jpg"}
                  alt={"Stacey Szigeti"}
                />
                <Shape />
                <h4>Stacey Szigeti</h4>
                <p>CTO, Co-founder</p>
              </Card>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/zs_kozeli.jpg"}
                  alt={"Zsófia Vajai"}
                />
                <Shape />
                <h4>Zsófia Vajai</h4>
                <p>CMO, Co-founder</p>
              </Card>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/laci_kozeli.jpg"}
                  alt={"László Fehér"}
                />
                <Shape />
                <h4>László Fehér</h4>
                <p>Chief Electrical Design, Co-founder</p>
              </Card>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/kristof_kozeli.jpg"}
                  alt={"Kristóf Damokos"}
                />
                <Shape />
                <h4>Kristóf Damokos</h4>
                <p>Chief DecOps Engineer, Co-founder</p>
              </Card>
              <Card>
                <StaticImage
                  style={CardImageStyle}
                  src={"../images/peter_kozeli.jpg"}
                  alt={"Peter Selmeci"}
                />
                <Shape />
                <h4>Peter Selmeci</h4>
                <p>Advisory</p>
              </Card>
            </Grid>
          </Crew>
          <Title>
            <h2>Our Investors</h2>
            <Underline />
          </Title>
          <Investors>
            <div>
              <StaticImage
                style={{ height: "120px" }}
                src={"../images/ujbuda.jpg"}
                alt={"Újbuda"}
                objectFit={"contain"}
              />
              <h4>Újbuda</h4>
              <p>pre-seed</p>
            </div>
            <div>
              <StaticImage
                style={{ height: "120px" }}
                src={"../images/hiventures_color.jpg"}
                alt={"Hiventures"}
                objectFit={"contain"}
              />
              <h4>Hiventures</h4>
              <p>pre-seed</p>
            </div>
            <div>
              <StaticImage
                style={{ height: "120px" }}
                src={"../images/hiventures_color.jpg"}
                alt={"Hiventures"}
                objectFit={"contain"}
              />
              <h4>Hiventures</h4>
              <p>seed</p>
            </div>
          </Investors>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default AboutUs;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  h1 {
    color: var(--color-orange);
    font-weight: 600;
  }
`;

const First = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 64px;
  align-items: center;
  margin-bottom: 32px;

  p {
    text-align: justify;
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

const Second = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 64px;
  text-align: justify;
  margin-top: 32px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

const Crew = styled.div`
  text-align: center;
`;
const Title = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    margin-top: 32px;
  }
  div {
    margin: 32px 0;
  }
`;

const Grid = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
  display: grid;
  place-items: center;
  grid-template-columns: 432px 432px 432px;
  column-gap: 20px;
  row-gap: 50px;
  @media (max-width: 1475px) {
    grid-template-columns: 432px 432px;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 340px 340px;
  }
  @media (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  width: 432px;
  height: calc(432px * 1.5);
  border-radius: 20px;
  box-shadow: 0px 8px 30px rgba(196, 196, 196, 0.4);
  overflow: hidden;
  position: relative;
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h4 {
    margin: 0;
    font-weight: 600;
    z-index: 99;
    /* font-size: min(max(2rem, calc(2rem + ((1vw - 0.48rem) * 0.5556))), 2.4rem); */
    font-size: 2rem;
    @media (min-width: 480px) {
      font-size: calc(2rem + ((1vw - 0.48rem) * 0.5556));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2.4rem;
    }
    min-height: 0vw;
    color: var(--color-orange);
    margin-bottom: 20px;
  }
  p {
    /* font-size: min(max(1.5rem, calc(1.5rem + ((1vw - 1rem) * 3))), 2rem); */
    font-size: 1.5rem;
    @media (min-width: 480px) {
      font-size: calc(1.5rem + ((1vw - 0.48rem) * 0.6944));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 2rem;
    }
    min-height: 0vw;
    margin: 0;
    z-index: 99;
    margin-bottom: 50px;
  }

  @media (max-width: 1020px) {
    width: 340px;
    height: calc(340px * 1.5);
    p {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 372px) {
    width: 288px;
    height: calc(288px * 1.5);
    p {
      margin-bottom: 20px;
    }
  }
`;
// 🎨🎨🎨
const Shape = styled.div`
  border-radius: 100%;
  width: 200%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-25%, 75%);
`;

const Investors = styled.div`
  display: flex;
  place-items: center;
  justify-content: center;
  margin-bottom: 64px;
  div {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & div {
      width: 100%;
    }
  }
  h4 {
    margin-top: 16px;
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    div {
      width: 100%;
      max-width: 300px;
    }
  }
`;
